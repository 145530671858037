import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import { Grid, Box, Typography, Button, Modal, TextField } from "@mui/material";

import KempegowdaImage from "../images/kemp.jpg";
import LawImage from "../images/jain1.jpg";
import ManagementImage from "../images/mgt2.jpg";

const images = [
  {
    src: KempegowdaImage,
    title: "Explore Kempegowda Heritage",
    description: "Dive into the rich culture and history of Kempegowda.",
    action: "Learn More",
  },
  {
    src: LawImage,
    title: "Explore The Jain College",
    description: "Empowering students with excellence and innovation for a brighter future.",
    action: "Join Now",
  },
  {
    src: ManagementImage,
    title: "Management Strategies for Success",
    description: "Shape your career with modern management strategies.",
    action: "Get Started",
  },
];

const Banner = () => {
  const swiperRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
  });

  useEffect(() => {
    const handleKeydown = (event) => {
      if (swiperRef.current) {
        if (event.key === "ArrowRight") swiperRef.current.slideNext();
        if (event.key === "ArrowLeft") swiperRef.current.slidePrev();
      }
    };
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setModalOpen(true);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted!");
    setModalOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "50vh",
        background: "linear-gradient(to bottom, #f4f4f4, #e0e0e0)",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
      }}
    >
      {/* Swiper Component */}
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, EffectFade]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        effect="fade"
        style={{ height: "100%", width: "100%" }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                backgroundImage: `url(${item.src})`,
                backgroundSize: "cover",
                backgroundPosition:
                  index === 0 ? "center -200px" : // For the first image
                  index === 1 ? "center -90px" : // For the second image
                  "center -110px", // For the third image,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                filter: "brightness(0.7)",
                transition: "transform 0.5s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {/* Content Overlay with Title & CTA */}
              <Box
                sx={{
                  textAlign: "center",
                  color: "white",
                  px: 4,
                  py: 3,
                  borderRadius: "8px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  transition: "all 0.3s ease",
                  maxWidth: "400px",
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ my: 2, fontSize: "16px" }}>
                  {item.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      "https://www.getmycollege.com/colleges?state=karnataka")
                  }
                  sx={{
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  {item.action}
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      Modal Popup Form
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "400px",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "25px",
            padding: "16px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
            marginTop: "20vh",
            position: "relative",
            "@media (max-width:600px)": {
              maxWidth: "90%",
            },
          }}
        >
          <Button
            onClick={() => setModalOpen(false)}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              padding: "5px",
              backgroundColor: "transparent",
              color: "#000",
              fontSize: "20px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#FF0000",
              },
            }}
          >
            X
          </Button>

          <Typography
            variant="h6"
            sx={{
              marginBottom: "16px",
              textAlign: "center",
              color: "#588CD0",
              fontFamily: "inherit",
            }}
          >
            APPLICATION DETAILS
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              required
              sx={{ marginBottom: "16px" }}
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
              type="email"
              sx={{ marginBottom: "16px" }}
            />
            <TextField
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              required
              type="tel"
              sx={{ marginBottom: "16px" }}
            />
            <TextField
              label="Course"
              name="course"
              value={formData.course}
              onChange={handleInputChange}
              fullWidth
              required
              sx={{ marginBottom: "16px" }}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  backgroundColor: "#588CD0",
                  borderRadius: "35px",
                  padding: "10px 20px",
                  transition: "transform 0.3s",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Banner;
