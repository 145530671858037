import React, { useState } from "react";
import { AppBar, Toolbar, Box, Button, Drawer, IconButton, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from '../images/logo.png';

const Appbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  const handleLogoClick = () => {
    window.location.href = "https://www.getmycollege.com/colleges?state=karnataka"; // Open the link in the same tab
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#1A237E",
          padding: "10px",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Logo with Click Event */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton onClick={handleLogoClick}>
              <img src={logo} alt="Logo" style={{ height: "60px", borderRadius: "50%" }} />
            </IconButton>
          </Box>

          {/* Navigation Buttons */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 2,
            }}
          >
            {[
              { label: "Home", url: "/" },
              { label: "About Us", url: "https://www.getmycollege.com/about-us" },
              { label: "Top Colleges In Bangalore", url: "https://www.getmycollege.com/" },
              { label: "Contact Us", url: "https://www.getmycollege.com/contact-us" },
            ].map((item) => (
              <Button
                key={item.label}
                variant="text"
                sx={{
                  fontFamily: "inherit",
                  color: "#FFC107",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  transition: "0.3s",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                }}
                onClick={() => handleNavigation(item.url)}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            sx={{ display: { xs: "flex", md: "none" }, color: "#FFC107" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile View */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: 2,
            backgroundColor: "#1A237E",
            height: "100%",
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 2,
            }}
          >
            <IconButton onClick={toggleDrawer(false)} sx={{ color: "#FFC107" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Drawer List Items */}
          <List>
            {[
              { label: "Home", url: "/" },
              { label: "About Us", url: "https://www.getmycollege.com/about-us" },
              { label: "Top Colleges In Bangalore", url: "https://www.getmycollege.com/" },
              { label: "Contact Us", url: "https://www.getmycollege.com/contact-us" },
            ].map((item) => (
              <ListItem
                button
                key={item.label}
                sx={{ color: "#FFC107" }}
                onClick={() => {
                  handleNavigation(item.url);
                  toggleDrawer(false)();
                }}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Appbar;
