import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import img from '../images/rv2.jpg';
import im from '../images/bms.jpg';
import i from '../images/hori.jpg';
import hoodie from '../images/med.jpg';
import sneak from '../images/grad.webp';
import denim from '../images/law1.jpg';
import tee from '../images/bit.jpg';

const Card2 = () => {
    const featuredItems = [
        { id: 1, image: img },
        { id: 2, image: im },
        { id: 3, image: i },
        { id: 4, image: tee },
    ];

    const products = [
        { id: 1, image: hoodie, text: "Medical", color: "#FF5733" },  // Orange for Medical
        { id: 2, image: sneak, text: "Engineer", color: "#4CAF50" },  // Green for Engineer
        { id: 3, image: denim, text: "Law", color: "#3F51B5" },  // Blue for Law
        { id: 4, image: tee },
    ];

    return (
        <div>
            <Box
                sx={{
                    bgcolor: "#f9f9f9",
                    minHeight: "100vh",
                    padding: "20px",
                    mt: { xs: "40px", sm: "0px" },
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        textAlign: "center",
                        mb: 3,
                        fontWeight: "bold",
                        color: "#588CD0",
                    }}
                >
                    Top Colleges in Bangalore
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={5}>
                                <Carousel animation="slide" sx={{ borderRadius: "10px", overflow: "hidden" }}>
                                    {featuredItems.map((item) => (
                                        <Box
                                            key={item.id}
                                            sx={{
                                                height: "390px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                position: "relative",
                                                transition: "transform 0.3s, box-shadow 0.5s",
                                                '&:hover': {
                                                    transform: "scale(1.03)",
                                                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                                },
                                            }}
                                        >
                                            <img
                                                src={item.image}
                                                alt="carousel"
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            />
                                        </Box>
                                    ))}
                                </Carousel>
                            </Grid>

                            <Grid item xs={12} md={7} padding="20px">
                                <Grid container spacing={3}>
                                    {products.slice(0, 3).map((product) => (
                                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                                            <Card
                                                sx={{
                                                    transition: "transform 0.5s, box-shadow 0.5s",
                                                    position: "relative",
                                                    marginLeft:{xs:2,md:0},
                                                    '&:hover': {
                                                        transform: "scale(1.05)",
                                                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                                    },
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    height="330"
                                                    image={product.image}
                                                    alt={product.text}
                                                />
                                                <CardContent>
                                                    <Typography variant="h6">{product.text}</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 10,
                                                            left: 10,
                                                            color: product.color,  // Apply color directly to the text
                                                            fontWeight: "bold",
                                                            padding: '5px 10px',
                                                            borderRadius: '8px',
                                                        }}
                                                    >
                                                        {product.text}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => window.location.href = "https://www.getmycollege.com/"}
                                                        sx={{
                                                            transition: "transform 0.3s",
                                                            '&:hover': {
                                                                transform: "scale(1.05)",
                                                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                                            },
                                                        }}
                                                    >
                                                        Explore
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default Card2;
