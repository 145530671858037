import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/system";

const GradientBox = styled(Box)( {
  background: "linear-gradient(to right, #6a1b9a, #283593)",
  padding: "40px 20px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

function Application() {
  return (
    <div>
      {/* Top Section */}
      <Box sx={{ textAlign: "center", padding: "40px 20px" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#424242",
          }}
        >
          #1 India's Largest Unified Application Platform for College Admissions
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#ff9800",
          }}
        >
          2000+ Colleges = 1 Application Form
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#616161",
            margin: "20px 0",
          }}
        >
          Simplify your multiple college applications!
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1976d2",
            color: "#fff",
            padding: "10px 20px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          START YOUR APPLICATION
        </Button>
      </Box>
    </div>
  );
}

export default Application;
