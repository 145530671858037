import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import t1 from '../images/t1.jpg';

const About = () => {
  const handleLearnMoreClick = () => {
    window.location.href = "https://www.getmycollege.com/about-us"; // Opens link in the same tab
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', padding: '16px' }}>
   
      <Grid item xs={12} md={8}>
        <Box sx={{ padding: '16px', mt: { xs: '40px', md: '-70px' } }}> {/* Adjusted margin-top based on screen size */}
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bolder', marginBottom: '16px', textAlign: 'center'}}
          >
            About
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '24px',
              color: 'gray',
              textAlign: 'justify', 
              lineHeight: 1.6, 
            }}
          >
            Top Colleges In Bangalore offer high standards in scholarly pursuits alongside up-to-the-minute infrastructure
            and robust industry linkages for the students. Some of the most popular institutes are the Indian Institute of
            Science (IISc), Christ University, RV College of Engineering, and Such institutions are laden with heavy-weight
            faculty, research potential- opportunities, and campus cultural life. Moreover, owing to its title as the 'Silicon
            Valley of India, students get more scope for internships and networking leading to job opportunity availabilities,
            thus making top colleges in Bangalore a wise investment for students wanting to pursue excellence in their lives.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none' }}
              onClick={handleLearnMoreClick} 
            >
              Learn More
            </Button>
          </Box>
        </Box>
      </Grid>

      {/* Right Side - Image */}
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            border: '1px solid #ddd',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
          }}
        >
          <img
            src={t1}
            alt="Placeholder"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default About;
