import React from 'react';
import { Box } from '@mui/system';
import { Typography, Card, CardMedia, CardContent, Button } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import iisc from '../images/iisc.jpg';
import nls from '../images/nls.jpg';
import christ from '../images/christ.jpg';
import stjo from '../images/stjo.jpg';
import msr from '../images/msr.jpg';
import bmrc from '../images/bmrcl.png';
import pes from '../images/pes.jpg';
import rve from '../images/RVE.jpg';
import { grey } from '@mui/material/colors';

const colleges = [
  {
    name: "Indian Institute of Science (IISc)",
    description: "Bangalore, Malleshwaram",
    image: iisc,
  },
  {
    name: "National Law School University",
    description: "Bangalore, Nagarbhavi",
    image: nls,
  },
  {
    name: "Christ University",
    description: "Bangalore, Koramangala",
    image: christ,
  },
  {
    name: "St. Joseph College",
    description: "Bangalore, Richmond Town",
    image: stjo,
  },
  {
    name: "MS Ramaiah Institution",
    description: "Bangalore, Mathikere",
    image: msr,
  },
  {
    name: "BMR College",
    description: "Bangalore, K R Road",
    image: bmrc,
  },
  {
    name: "PES University",
    description: "Bangalore, Electronic City",
    image: pes,
  },
  {
    name: "R.V. College of Engineering",
    description: "Bangalore, Mysore Road",
    image: rve,
  },
];

const Card1 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 4 },
        marginBottom: { xs: 4, sm: 6 },
        mt: { xs: "64px", sm: "0px", md: '-60px' },
        maxWidth: '1200px', // Controls the maximum width to prevent expansion when zooming
        margin: 'auto', // Centers the content horizontally
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          marginBottom: 2,
          mt:{xs:-4,md:0},
          fontWeight: "bold",
          color: '#588CD0',
          fontFamily: 'inherit',
          fontSize: { xs: "24px", sm: "32px", md: "36px" },
        }}
      >
        Explore Top Colleges In Bangalore
      </Typography>

      <Slider {...settings}>
        {colleges.map((college, index) => (
          <Card
            key={index}
            sx={{
              flexShrink: 0,
              width: "200px",
              maxWidth: '370px',
              ml: { xs: 0, md: 0 },
              height: "auto",
              margin: { xs: 1, md: 0 },
              borderRadius: "15px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
              transition: "transform 0.3s",
              ":hover": {
                transform: "scale(1.05)",
              },
              margin: "0px 20px",
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={college.image}
              alt={college.name}
              sx={{
                objectFit: "cover",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            />
            <CardContent sx={{ padding: "16px" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: { xs: "18px", sm: "20px" },
                }}
              >
                {college.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: grey[600],
                  marginBottom: 2,
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                <PlaceIcon sx={{ fontSize: 16, marginRight: 1, color: grey[600] }} />
                {college.description}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "#1a237e",
                    color: "#1a237e",
                    "&:hover": {
                      borderColor: "#283593",
                      color: "#283593",
                      transform: "scale(1.05)",
                    },
                    borderRadius: "30px",
                    textTransform: "none",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                  onClick={() => window.location.href = "https://www.getmycollege.com/colleges?state=karnataka"}
                >
                  Apply Now
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Slider>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1a237e",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#283593",
              transform: "scale(1.05)",
            },
            borderRadius: "30px",
            textTransform: "none",
            fontSize: { xs: "14px", sm: "16px" },
          }}
          onClick={() => window.location.href = "https://www.getmycollege.com/colleges?state=karnataka"}
        >
          Explore More Colleges
        </Button>
      </Box>
    </Box>
  );
};

export default Card1;
