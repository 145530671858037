import logo from './logo.svg';
import './App.css';
import Card1 from './components/Card1';
import Appbar from './components/Appbar';
import Application from './components/Application';
import Banner from './components/Banner';
import About from './components/About';
import Footer from './components/Footer';
import Card2 from './components/Card2';
import About2 from './components/About2';
function App() {
  return (
    <div className="App" style={{overflow:'hidden'}}>

      <Appbar />
      <Banner />
      <Card2 />
      <Card1 />
      <About />
      <Application />
      <About2/>
      <Footer />

    </div>
  );
}

export default App;
