import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import t2 from '../images/t2.jpg';

const About = () => {
  const handleLearnMoreClick = () => {
    window.location.href = "https://www.getmycollege.com/"; // Open the link in the same tab
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', padding: '16px' }}>
      {/* Left Side - Text Content */}
      <Grid item xs={12} md={8}>
        <Box sx={{ padding: '16px' }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', marginBottom: '16px', textAlign: 'center' }}
          >
            Why Choose Us?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '24px',
              color: 'gray',
              textAlign: 'justify',
              lineHeight: 1.6,
            }}
          >
            Understanding colleges in Bangalore, for the right reasons, is one, if not the main, city in India offering great educational
            opportunities through various institutions. Some of India's most prized colleges and universities, which lure students from all
            Indian states and those in foreign lands, have established themselves in Bangalore. Hence, learning about the education landscape
            of Bangalore is for wise academic choices, which also allows students to enjoy the active culture of the city, student life, and
            employment opportunities it offers.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none' }}
              onClick={handleLearnMoreClick} // Open the link in the same tab
            >
              Learn More
            </Button>
          </Box>
        </Box>
      </Grid>

      {/* Right Side - Image */}
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            border: '1px solid #ddd',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
          }}
        >
          <img
            src={t2}
            alt="Placeholder"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default About;
